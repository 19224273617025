export class Calculator {

    constructor() {
        this.CARS = [];
        this.car = "B";
        this.firstCarKey = "B";
    }

    setCars(cars) {
        this.CARS = cars;
    }

    setCar(car) {
        try {
            localStorage.setItem("car", car);
        } catch (e) {
            // localstorage not availabel, can do nothing about it
        }
        this.car = car;
        $('#car_' + car).prop("checked", true);
        this.recalculateStep1();
        this.fillCarSummary();
        this.recalculateStep2();
    }

    setFirstCarKey(firstCarKey) {
        this.firstCarKey = firstCarKey;
    }

    formChecksStep2() {
        let error = false;
        for (let i = 1; i <= 10; i++) {
            if (!$('#sendung_' + i).is(':visible'))
                continue;
            if (!parseInt($('#anzahl_' + i).val()))
                error = true;
            if (!parseInt($('#breite_' + i).val()))
                error = true;
            if (!parseInt($('#laenge_' + i).val()))
                error = true;
            if (!parseInt($('#hoehe_' + i).val()))
                error = true;
            if (!$('#was_' + i).val())
                error = true;
        }
        if (!parseInt($('#gewicht').val()))
            error = true;
        if (!error) {
            $("input").removeClass("has-error");
            $("#submitbutton").removeClass("disabled").prop("disabled", false);
        } else {
            $("#submitbutton").addClass("disabled").prop("disabled", true);
        }
    }

    hideErrorOverlay() {
        $('#errorOverlay').hide();
    }


    showError(error) {
        $('#errorP').html(error);
        $('#errorOverlay').show();
    }


    recalculateStep1(step) {
        if (step === undefined)
            step = 1;
        const me = this;
        me.step = step;
        $.post({
            url: "/api/get_quobra",
            data: {
                "abhdat": $('#abhdat').val(),
                "anplz": $('#anplz').val(),
                "anort": $('#anort').val(),
                "anland": $('#anland').val(),
                "abplz": $('#abplz').val(),
                "abort": $('#abort').val(),
                "abland": $('#abland').val(),
                "car": this.car,
            }
        }).done(function (data) {
            if (data["ERROR"] !== undefined) {
                let showError = true;
                for (let i = 0; i < data["ERROR"].length; i++) {
                    if (data["ERROR"][i].includes("Pflichtfeld"))
                        showError = false;
                }
                if (showError)
                    me.showError(data["ERROR"]);
            }
            if (data["preise"] === undefined)
                return;
            $('#km').html(data["km"] + " km");
            let price = data["preise"][me.car]["GESAMT_PREIS"];
            let price_with_vat = price * 1.19;
            let price_vat = $("#price_vat");
            price = price.toFixed(2) + " EUR";
            price_with_vat = price_with_vat.toFixed(2) + " EUR";
            $("#price").html(price.replace('.', ','));
            if (price_vat.length)
                price_vat.html(price_with_vat.replace('.', ','));
            if (me.step === 1)
                $("#submitbutton").removeClass("disabled").prop("disabled", false);
            if (me.step === 2)
                me.formChecksStep2();
            if (me.step >= 3) {
                $('#abland_text').val($('#abland').val())
                $('#abplz_text').val($('#abpkz').val())
                $('#abort_text').val($('#abort').val())
                $('#anland_text').val($('#anland').val())
                $('#anplz_text').val($('#anplz').val())
                $('#anort_text').val($('#anort').val())
            }
            $("input").removeClass("has-error");
            if (window.errors === undefined)
                me.hideErrorOverlay();
        });
    }

    fillCarSummary() {
        let html = "<b>" + this.CARS[this.car]["description"] + "</b><br>\n";
        html += "max. (" + this.CARS[this.car]["max_palettes"] + " Paletten max. " + this.CARS[this.car]["max_length"] + "x";
        html += this.CARS[this.car]["max_width"] + "x" + this.CARS[this.car]["max_height"] + "cm, <b>";
        html += this.CARS[this.car]["max_weight"] + "kg</b>)";
        $('#carinfos').html(html);
    }


    recalculateStep2() {
        let area= 0;
        let height= 0;
        let length = 0;
        let width = 0;

        const weight = parseInt($('#gewicht').val());

        if (weight > this.CARS[this.car]["max_weight"]) {
            this.showHint(
                "Diese Sendung ist zu schwer für das aktuell gewählte Fahrzeug.<br><br>" +
                "Das Maximalgewicht beträgt " + this.CARS[this.car]["max_weight"] + "kg.", "gewicht"
            );
            $("#submitbutton").addClass("disabled").prop("disabled", true);
            return;
        }

        for (let i = 1; i <= 10; i++) {
            let tmp_num = parseFloat($('#anzahl_' + i).val()); tmp_num = isNaN(tmp_num) ? 0 : tmp_num;
            let tmp_length = parseFloat($('#laenge_' + i).val()); tmp_length = isNaN(tmp_length) ? 0 : tmp_length;
            let tmp_width = parseFloat($('#breite_' + i).val()); tmp_width = isNaN(tmp_width) ? 0 : tmp_width;
            area += tmp_num * tmp_length * tmp_width;
            width = Math.max(tmp_width, width);
            length = Math.max(tmp_length, length);
        }

        if (area > this.CARS[this.car]["max_area"]) {
            this.showHint(
                "Diese Sendung braucht zu viel Fläche für das aktuell gewählte Fahrzeug.<br><br>" +
                "Die maximale Fläche beträgt " + (this.CARS[this.car]["max_area"] / 10000) + "m².", "laenge"
            );
            $("#submitbutton").addClass("disabled").prop("disabled", true);
            return;
        }

        for (let i = 1; i <= 10; i++) {
            let tmp_height = parseFloat($('#hoehe_' + i).val()); tmp_height = isNaN(tmp_height) ? 0 : tmp_height;
            height = Math.max(height, tmp_height);
        }

        if (height > this.CARS[this.car]["max_height"]) {
            this.showHint(
                "Diese Sendung ist zu hoch für das aktuell gewählte Fahrzeug.<br><br>" +
                "Die maximale Höhe " + this.CARS[this.car]["max_height"] + "cm.", "hoehe"
            );
            $("#submitbutton").addClass("disabled").prop("disabled", true);
            return;
        }
        if (length > this.CARS[this.car]["max_length"]) {
            this.showHint(
                "Diese Sendung ist zu lang für das aktuell gewählte Fahrzeug.<br><br>" +
                "Die maximale Länge " + this.CARS[this.car]["max_length"] + "cm.", "hoehe"
            );
            $("#submitbutton").addClass("disabled").prop("disabled", true);
            return;
        }
        if (width > this.CARS[this.car]["max_width"]) {
            this.showHint(
                "Diese Sendung ist zu breit für das aktuell gewählte Fahrzeug.<br><br>" +
                "Die maximale Breite " + this.CARS[this.car]["max_width"] + "cm.", "breite"
            );
            $("#submitbutton").addClass("disabled").prop("disabled", true);
            return;
        }
        this.formChecksStep2();
        this.recalculateStep1(2);
    }


    showHint(hint, input, tooBig = false) {
        $("#" + input).addClass("has-error");
        $('#hintsText').html(hint);
        $('#hintsOverlay').css("display", "flex");
        if (tooBig) {
            $('#choose_car_button').hide();
        } else {
            $('#choose_car_button').show();
        }
    }


    closeHintsOverlay() {
        $('#hintsOverlay').css("display", "none");
        $('#choose_car_button').show();
    }


    chooseBiggerCar() {
        let area= 0;
        let height= 0;
        let length = 0;
        let width = 0;
        let weight = parseInt($('#gewicht').val());
        weight = isNaN(weight) ? 0 : weight;

        for (let i = 1; i <= 10; i++) {
            let tmp_num = parseFloat($('#anzahl_' + i).val()); tmp_num = isNaN(tmp_num) ? 0 : tmp_num;
            let tmp_length = parseFloat($('#laenge_' + i).val()); tmp_length = isNaN(tmp_length) ? 0 : tmp_length;
            let tmp_width = parseFloat($('#breite_' + i).val()); tmp_width = isNaN(tmp_width) ? 0 : tmp_width;
            area += tmp_num * tmp_length * tmp_width;
            width = Math.max(tmp_width, width);
            length = Math.max(tmp_length, length);
        }

        for (let i = 1; i <= 10; i++) {
            let tmp_height = parseFloat($('#hoehe_' + i).val()); tmp_height = isNaN(tmp_height) ? 0 : tmp_height;
            height = Math.max(height, tmp_height);
        }

        let lastKey = this.firstCarKey;
        for (const [key, car] of Object.entries(this.CARS)) {
            lastKey = key;
            if (weight <= car["max_weight"] &&
                area <= car["max_area"] &&
                height <= car["max_height"] &&
                length <= car["max_length"] &&
                width <= car["max_width"])
            {
                try {
                    localStorage.setItem("car", key);
                } catch (e) {
                    // localstorage not availabel, can do nothing about it
                }
                this.car = key;
                this.fillCarSummary(key);
                $('.carinput').val([key]);
                $('#car_' + key).prop('checked', true);
                this.recalculateStep1(2);
                return this.closeHintsOverlay();
            }
        }
        try {
            localStorage.setItem("car", lastKey);
        } catch (e) {
            // localstorage not availabel, can do nothing about it
        }
        this.car = lastKey;
        if (weight > this.CARS[this.car]["max_weight"]) {
            this.showHint(
                "Diese Sendung ist zu schwer für alle verfügbaren Fahrzeuge.<br><br>" +
                "Das Maximalgewicht beträgt " + this.CARS[this.car]["max_weight"] + "kg.", "gewicht", true
            );
            $("#submitbutton").addClass("disabled").prop("disabled", true);
            return;
        }
        if (area > this.CARS[this.car]["max_area"]) {
            this.showHint(
                "Diese Sendung braucht zu viel Fläsche für alle verfügbaren Fahrzeuge.<br><br>" +
                "Die maximale Fläche beträgt " + (this.CARS[this.car]["max_area"] / 10000) + "m².", "area", true
            );
            $("#submitbutton").addClass("disabled").prop("disabled", true);
            return;
        }
        if (length > this.CARS[this.car]["max_length"]) {
            this.showHint(
                "Diese Sendung ist zu lang für alle verfügbaren Fahrzeuge.<br><br>" +
                "Die maximale Länge beträgt " + this.CARS[this.car]["max_length"] + "cm.", "laenge", true
            );
            $("#submitbutton").addClass("disabled").prop("disabled", true);
            return;
        }
        if (width > this.CARS[this.car]["max_width"]) {
            this.showHint(
                "Diese Sendung ist zu breit für alle verfügbaren Fahrzeuge.<br><br>" +
                "Die maximale Breite beträgt " + this.CARS[this.car]["max_width"] + "cm.", "breite", true
            );
            $("#submitbutton").addClass("disabled").prop("disabled", true);
            return;
        }
        if (height > this.CARS[this.car]["max_height"]) {
            this.showHint(
                "Diese Sendung ist zu hoch für alle verfügbaren Fahrzeuge.<br><br>" +
                "Die maximale Höhe " + this.CARS[this.car]["max_height"] + "cm.", "hoehe", true
            );
            $("#submitbutton").addClass("disabled").prop("disabled", true);
            return;
        }
        $('#choose_car_button').hide();
        $("#submitbutton").addClass("disabled").prop("disabled", true);
    }
}

// noinspection JSUnresolvedReference
// -> global.Calculator is an instance. PHPStorm thinks it's the class and
// wants to see a static method there.

import './styles/app.css';
import '../node_modules/jquery-datetimepicker/jquery.datetimepicker.css';
import '../node_modules/jquery-ui/dist/themes/base/jquery-ui.min.css';

// jquery
const $ = require('jquery');
// create global $ and jQuery variables
global.$ = global.jQuery = $;
import datetimepickerFactory from "jquery-datetimepicker";
jQuery.datetimepicker.setLocale('de');
require("jquery-ui");
require("jquery-ui/ui/widgets/autocomplete");

import {Calculator} from './js/Calculator';
global.Calculator = new Calculator();

global.recalculateStep1 = function (step) {
    global.Calculator.recalculateStep1(step);
}

global.setCar = function (car) {
    global.Calculator.setCar(car);
}

global.formSubmit = function() {
    if ($("#submitbutton").prop("disabled") !== "disabled")
        $('form').submit();
}
